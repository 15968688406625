import React, { useCallback, useEffect, useState } from "react";
import Table from "../table/Table";
import Filter from "./Filter";
import { useSearchParams } from "react-router-dom";
import { eventBus } from "../../services/eventBus";
import { getUrlSearchParams } from "../../utils/urls";

const ListView = ({ meta, filters, title, modalSize = "lg", pagination }) => {
  const { columns, queryService, actions, addModal, addButtonText, modelName } =
    meta;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({ rowData: [], total: 0 });

  const performQuery = useCallback(() => {
    const urlSearchParams = getUrlSearchParams(searchParams);
    setIsLoading(true);
    const res = queryService(urlSearchParams).then((info) => {
      const { meta, data } = info;
      setTableData({
        rowData: data || [],
        total: meta?.total || 0,
      });
      setIsLoading(false);
      return info;
    });
    return res;
  }, [queryService, searchParams]);

  useEffect(() => {
    eventBus.subscribe("refresh_table", () => performQuery());

    return () => {
      eventBus.unsubscribe("refresh_table");
    };
  }, [performQuery]);

  return (
    <>
      {filters && (
        <Filter
          filtersMeta={filters}
          queryService={performQuery}
          params={searchParams}
          setParams={setSearchParams}
        />
      )}
      <Table
        tableData={tableData}
        setTableData={setTableData}
        isLoading={isLoading}
        title={title}
        columns={columns}
        performQuery={performQuery}
        actions={actions}
        addModal={addModal}
        data={[]}
        addButtonText={addButtonText}
        refreshEvent="refresh_table"
        params={searchParams}
        setParams={setSearchParams}
        modelName={modelName}
        setIsLoading={setIsLoading}
        pagination={pagination}
      />
    </>
  );
};

export default ListView;
