import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";

const Loader = ({ isLoading, children, minHeight = true, className = "" }) => (
  <>
    <Container
      fluid
      className={`${minHeight ? className + " m-vh-50 d-flex" : className}`}
    >
      {isLoading ? (
        <Row className="justify-content-center align-self-center w-100 text-center">
          <Spinner animation="border" variant="primary" />
        </Row>
      ) : (
        children
      )}
    </Container>
  </>
);

export default Loader;
