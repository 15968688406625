import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";
import profileFields from "../../configs/meta/formFields/profileFields";
import useAuth from "../../hooks/useAuth";
import { updateProfile } from "../../services/api/queries/auth";
import NotyfContext from "../../contexts/NotyfContext";

const submitButton = {
  className: "btn btn-primary rounded-0",
  label: "Update",
  isDisabled: false,
};

const Settings = () => {
  const { user, updateUser } = useAuth();
  const notyf = useContext(NotyfContext);

  const handleSubmit = async (data) => {
    try {
      const res = await updateProfile({ ...data, id: user.id });
      if (!res.success) throw res.data;

      updateUser(res.data);

      const message = "Profile Updated!";
      notyf.open({
        type: "success",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    } catch (error) {
      const message = error.error || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <Card>
          <Card.Header>
            <h1 className="h3 mb-3">Update Profile</h1>
          </Card.Header>
          <Card.Body>
            <FormBuilder
              fields={profileFields}
              submitButton={submitButton}
              defaultValues={{ ...(user || {}), image: user?.image || [] }}
              onSubmit={handleSubmit}
              doNotReset
            />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Settings;
