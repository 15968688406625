import {
  JOIN_DISCUSSION,
  MY_COURSES,
  MY_DISCUSSION,
  NOTICES,
  PAYMENT_HISTORY,
} from "constants/routes";
import {
  faBullhorn,
  faChalkboardTeacher,
  faDirections,
  faFileInvoiceDollar,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

const discussionSection = [
  {
    icon: faChalkboardTeacher,
    title: "Discussion Forum",
    children: [
      {
        href: JOIN_DISCUSSION,
        title: "Join Discussion",
      },
      {
        href: MY_DISCUSSION,
        title: "My Questions",
      },
    ],
  },
];

const coursesSection = [
  {
    href: MY_COURSES,
    icon: faGraduationCap,
    title: "My Courses",
  },
];

const paymentHistorySection = [
  {
    href: PAYMENT_HISTORY,
    icon: faFileInvoiceDollar,
    title: "Payment History",
  },
];

const noticeSection = [
  {
    href: NOTICES,
    icon: faBullhorn,
    title: "Notice Board",
  },
];

const navItems = [
  {
    key: "courses",
    pages: coursesSection,
  },
  {
    key: "discussion",
    pages: discussionSection,
  },
  {
    key: "notice",
    pages: noticeSection,
  },
  {
    key: "payment",
    pages: paymentHistorySection,
  },
  {
    key: "pages",
    pages: [
      {
        href: "",
        redirect: process.env.REACT_APP_WEBSITE_BASE_URL,
        icon: faDirections,
        title: "Go to Website",
      },
    ],
  },
];

export default navItems;
