/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ExamQuestion from "../../../components/exam/ExamQuestion";
import { Form } from "../../../components/formBuilder/FormInput";
import {
  createExam,
  progressCourse,
  questionFinalSubmit,
} from "../../../services/api/queries/courses";
import { SidebarContext } from "../../../contexts/SidebarContext";

const svgList = {
  notePencil: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="30"
      fill="none"
      viewBox="0 0 34 35"
    >
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.25"
        d="M23.738 1H3.73C2.225 1 1 2.196 1 3.665v27.9c0 1.47 1.225 2.666 2.73 2.666h20.008c1.506 0 2.73-1.196 2.73-2.666v-27.9c0-1.47-1.224-2.665-2.73-2.665zm1.88 30.565c0 1.012-.844 1.835-1.88 1.835H3.73c-1.036 0-1.88-.823-1.88-1.835v-27.9c0-1.011.844-1.834 1.88-1.834h20.008c1.037 0 1.88.823 1.88 1.834v27.9h0z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.25"
        d="M6.727 6.026c-.902 0-1.635.717-1.635 1.597S5.825 9.22 6.727 9.22s1.636-.716 1.636-1.597c0-.88-.734-1.597-1.636-1.597zm0 2.363a.776.776 0 01-.784-.766c0-.422.352-.766.784-.766.433 0 .785.344.785.766a.776.776 0 01-.785.766zM22.388 7.207h-11.21a.42.42 0 00-.426.415c0 .23.19.416.425.416h11.211a.42.42 0 00.426-.416.42.42 0 00-.426-.415zM6.727 12.168c-.902 0-1.635.716-1.635 1.597 0 .88.733 1.596 1.635 1.596s1.636-.716 1.636-1.596c0-.88-.734-1.597-1.636-1.597zm0 2.363a.776.776 0 01-.784-.766c0-.423.352-.766.784-.766.433 0 .785.343.785.766a.776.776 0 01-.785.766zM22.388 13.349h-11.21a.42.42 0 00-.426.415c0 .23.19.415.425.415h11.211a.42.42 0 00.426-.415.42.42 0 00-.426-.415zM6.727 18.31c-.902 0-1.635.717-1.635 1.597s.733 1.597 1.635 1.597 1.636-.716 1.636-1.597c0-.88-.734-1.596-1.636-1.596zm0 2.363a.776.776 0 01-.784-.766c0-.422.352-.766.784-.766.433 0 .785.344.785.766a.776.776 0 01-.785.766zM22.388 19.493h-11.21a.42.42 0 00-.426.416c0 .229.19.415.425.415h11.211a.42.42 0 00.426-.416.42.42 0 00-.426-.415zM6.727 24.454c-.902 0-1.635.716-1.635 1.597 0 .88.733 1.597 1.635 1.597s1.636-.717 1.636-1.597-.734-1.597-1.636-1.597zm0 2.363a.776.776 0 01-.784-.766c0-.422.352-.766.784-.766.433 0 .785.343.785.766a.776.776 0 01-.785.766zM22.388 25.635h-11.21a.42.42 0 00-.426.415c0 .23.19.415.425.415h11.211a.42.42 0 00.426-.415.42.42 0 00-.426-.415zM32.984 28.205c.005-.026.016-.052.016-.077V5.039c0-.761-.634-1.38-1.415-1.38h-2.567c-.78 0-1.415.619-1.415 1.38v23.088s0 0 0 0c0 .027.011.052.017.078.006.029.007.059.018.085v.001l2.273 5.144c.067.152.22.251.39.251s.324-.099.392-.25l2.272-5.145v-.001c.012-.026.013-.057.019-.085zm-4.53-20.44h1.42v19.946h-1.42V7.765zm2.271 0h1.425v19.946h-1.425V7.765zM29.018 4.49h2.567c.311 0 .565.247.565.55v1.895h-3.695V5.04c0-.303.252-.55.563-.55zm1.284 27.733l-1.626-3.68h3.252l-1.626 3.68z"
      ></path>
    </svg>
  ),
  marksPaper: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      fill="none"
      viewBox="0 0 32 36"
    >
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.25"
        d="M3.822 1h24.356c.776 0 1.482.272 1.993.71.512.437.829 1.041.829 1.705v29.17c0 .664-.317 1.268-.829 1.706-.511.437-1.217.709-1.993.709H3.822c-.776 0-1.482-.272-1.993-.71C1.317 33.854 1 33.25 1 32.585V3.415c0-.664.317-1.268.829-1.706C2.34 1.272 3.046 1 3.822 1zm24.356.54H3.822c-.602 0-1.15.21-1.548.55-.397.34-.643.81-.643 1.325v29.17c0 .515.246.984.643 1.324.398.34.946.551 1.548.551h24.356c.602 0 1.15-.21 1.548-.55.397-.34.643-.81.643-1.325V3.414c0-.515-.246-.984-.643-1.324a2.383 2.383 0 00-1.548-.551z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.25"
        d="M6.67 5.817a.276.276 0 010-.551h18.913a.276.276 0 010 .551H6.67zM6.67 10.153a.276.276 0 010-.551h18.913a.276.276 0 010 .551H6.67zM6.67 14.488a.276.276 0 010-.551h18.913a.276.276 0 010 .551H6.67zM13.05 28.986l.698-6.615.855-.23 4.062 5.34-.905.243-1.186-1.63-2.509.673-.17 1.993-.844.226zm1.071-2.927l2.035-.545-1.072-1.493c-.325-.453-.579-.83-.758-1.13.027.406.021.816-.016 1.233l-.189 1.935zM17.95 24.186l-.293-1.09-1.082.29-.122-.456 1.082-.29-.29-1.083.461-.123.29 1.082 1.083-.29.122.456-1.083.29.293 1.09-.461.124z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.25"
        d="M16.129 18.918c1.738 0 3.31.704 4.449 1.843a6.273 6.273 0 011.843 4.45c0 1.737-.704 3.31-1.843 4.449a6.273 6.273 0 01-4.45 1.843 6.273 6.273 0 01-4.449-1.843 6.274 6.274 0 01-1.843-4.45c0-1.737.704-3.31 1.843-4.449a6.273 6.273 0 014.45-1.843zm4.059 2.232a5.723 5.723 0 00-4.06-1.681c-1.585 0-3.02.643-4.059 1.681a5.723 5.723 0 00-1.681 4.06c0 1.584.643 3.02 1.681 4.06a5.723 5.723 0 004.06 1.68c1.585 0 3.02-.642 4.059-1.68a5.723 5.723 0 001.681-4.06c0-1.586-.642-3.02-1.681-4.06z"
      ></path>
    </svg>
  ),
  examClock: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        d="M18 18h-1a1 1 0 00.684.948l.317-.948zm1-8.334a1 1 0 00-2 0h2zm3.684 10.949a1 1 0 10.633-1.897l-.633 1.897zM19.001 18V9.666h-2V18h2zm-1.317.948l5 1.667.633-1.897-5-1.667-.633 1.897zM8.001 2.333h20v-2H8v2zM33.667 8v20h2V8h-2zm-5.666 25.666H8v2h20v-2zM2.334 28V8h-2v20h2zm5.667 5.666A5.667 5.667 0 012.334 28h-2a7.667 7.667 0 007.667 7.666v-2zM33.667 28a5.667 5.667 0 01-5.666 5.666v2A7.667 7.667 0 0035.667 28h-2zM28.001 2.333A5.667 5.667 0 0133.667 8h2A7.667 7.667 0 0028.001.333v2zm-20-2A7.667 7.667 0 00.334 8h2a5.667 5.667 0 015.667-5.667v-2z"
      ></path>
    </svg>
  ),
  dottedCube: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="22"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="4.421" cy="3.684" r="3.684" fill="#fff"></circle>
      <circle cx="4.421" cy="14.001" r="3.684" fill="#fff"></circle>
      <circle cx="4.421" cy="24.315" r="3.684" fill="#fff"></circle>
      <circle cx="13.999" cy="3.684" r="3.684" fill="#fff"></circle>
      <circle cx="13.999" cy="14.001" r="3.684" fill="#fff"></circle>
      <circle cx="13.999" cy="24.315" r="3.684" fill="#fff"></circle>
      <circle cx="23.579" cy="3.684" r="3.684" fill="#fff"></circle>
      <circle cx="23.579" cy="14.001" r="3.684" fill="#fff"></circle>
      <circle cx="23.579" cy="24.315" r="3.684" fill="#fff"></circle>
    </svg>
  ),
  timeOutClock: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="28"
      fill="none"
      viewBox="0 0 39 43"
    >
      <circle
        cx="19.903"
        cy="23.007"
        r="17.597"
        stroke="#fff"
        strokeWidth="3"
      ></circle>
      <path
        stroke="#fff"
        strokeWidth="3"
        d="M19.393 10.945L19.393 23.006"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="3"
        d="M26.938 24.506L17.892 24.506"
      ></path>
      <rect
        width="6.031"
        height="3.015"
        x="16.889"
        y="0.895"
        fill="#fff"
        rx="1.508"
      ></rect>
    </svg>
  ),
  paperPlane: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        d="M5.888 10.225L2.089 8.96c-2.061-.687-2.061-3.603 0-4.29L13.977.705c1.767-.59 3.45 1.093 2.86 2.86l-3.962 11.888c-.688 2.061-3.604 2.061-4.291 0l-1.266-3.799a2.261 2.261 0 00-1.43-1.43z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.758 10.76c.251.247.445.553.56.896l1.265 3.799c.688 2.062 3.604 2.062 4.291 0l3.963-11.888c.296-.89.017-1.76-.56-2.327l-9.52 9.52z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#07969D"
        d="M7.317 11.656l-.237.08.237-.08zm-.56-.897l-.176-.176a.25.25 0 00.001.355l.176-.179zm1.826 4.696l.238-.08-.238.08zm4.291 0l.237.079-.237-.08zm3.963-11.888l-.238-.079.238.08zm-.56-2.327l.175-.178a.25.25 0 00-.352.001l.177.177zM7.554 11.577a2.51 2.51 0 00-.62-.996l-.352.357c.224.22.396.492.498.797l.474-.158zm1.267 3.799l-1.267-3.799-.474.159 1.266 3.798.475-.158zm3.816 0c-.611 1.833-3.205 1.833-3.816 0l-.475.158c.763 2.29 4.002 2.29 4.765 0l-.474-.158zm3.962-11.888l-3.962 11.888.474.158 3.963-11.888-.475-.158zm-.497-2.07c.514.507.761 1.278.497 2.07l.475.159a2.486 2.486 0 00-.622-2.585l-.35.356zm-.002-.355l-9.519 9.52.354.353 9.519-9.519-.354-.354z"
      ></path>
    </svg>
  ),
  paperPlaneTwo: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="25"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#07969D"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.77 23.249l-8.98-2.931c-4.874-1.591-4.874-8.343 0-9.935L32.893 1.21c4.18-1.364 8.156 2.53 6.763 6.623l-9.368 27.523c-1.625 4.773-8.519 4.773-10.144 0l-2.993-8.795a5.293 5.293 0 00-3.381-3.311z"
        opacity="0.4"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.318 25.285a5.22 5.22 0 011.324 2.078l2.993 8.794c1.625 4.773 8.52 4.773 10.144 0l9.368-27.523c.702-2.063.04-4.075-1.324-5.389l-22.505 22.04z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  upArrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="13"
      fill="none"
      viewBox="0 0 18 13"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9 4.784L2.56 12c-.585.656-1.535.656-2.12 0-.586-.657-.586-1.721 0-2.377l7.5-8.405c.585-.657 1.535-.657 2.12 0l7.5 8.405c.586.656.586 1.72 0 2.377-.585.656-1.535.656-2.12 0L9 4.784z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
};

const Exam = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [counter, setCounter] = useState(0);
  const [examData, setExamData] = useState({});
  const timerRef = useRef();
  const navigate = useNavigate();

  const { setHideFromDisplay } = useContext(SidebarContext);

  useEffect(() => {
    setHideFromDisplay(true);
  }, [setHideFromDisplay]);

  let prevSection = null;

  const params = useParams();

  const sortBasedOnSections = (questions, sections) => {
    let sorted = [];
    // console.log(sections);
    sections.forEach((sec) =>
      questions.forEach((ques) => {
        if (ques.section__name === sec) sorted.push(ques);
      })
    );
    return sorted;
  };

  const fetchExam = useCallback(async () => {
    try {
      const res = await createExam(params);
      if (!res.success) throw res.data;
      const questions = res?.data?.question_data?.questions;
      const sections = res?.data?.section;

      setExamData({
        exam: res.data.exam,
        user_exam: res.data.user_exam,
        questions: sections
          ? sortBasedOnSections(questions, sections)
          : questions,
        total_marks: res.data.question_data.total_marks,
      });
    } catch (error) {
      console.log(error);
    }
  }, [params]);

  const beginTimer = useCallback((initialTimer) => {
    setCounter(initialTimer);
    clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      setCounter((val) => {
        if (val < 2) {
          setTimeUp(true);
          setShowSubmitModal(true);
          clearInterval(timerRef.current);
          return 0;
        }
        return val - 1;
      });
    }, 1000);
  }, []);

  // Creating and exam
  useEffect(() => {
    fetchExam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prevent Reload
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "Changes";
      return "Changes";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  // Prevent Reload
  useEffect(() => {
    const popWindow = (e) => {
      e.preventDefault();
      window.history.pushState(null, "", window.location.href);
      e.returnValue = "Changes";
      return "Changes";
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", popWindow);
    return () => {
      window.removeEventListener("popstate", popWindow);
    };
  }, []);

  // Start Timer
  useEffect(() => {
    const duration = examData.exam?.duration;
    if (duration) {
      const counterValue =
        parseInt(duration.split(":")[0]) * 3600 +
        parseInt(duration.split(":")[1]) * 60 +
        parseInt(duration.split(":")[2]);
      beginTimer(counterValue);
    }
  }, [beginTimer, examData]);

  const onAnswerTap = (question_id) => {
    setSelectedQuestions((prev) => [...prev, question_id]);
  };

  function formatTime(duration) {
    const sec_num = parseInt(duration, 10);
    const hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    return hours + ":" + minutes + ":" + seconds;
  }
  const handleSolutionSubmit = async (val) => {
    const data = Object.keys(val).map((key) => ({
      user_exam_question: key,
      answer: val[key],
    }));

    const filterData = data.filter((item, idx) => {
      if (
        item.answer instanceof Array ||
        item.user_exam_question.includes("written")
      ) {
        return item;
      }
    });

    try {
      const res = await questionFinalSubmit(filterData, examData.user_exam.id);
      if (!res.success) throw res.data;
      await progressCourse({
        content_type: "exam",
        content_id: params.exam_id,
        id: params.course_id,
      });
      navigate(`/exam-result/${examData.user_exam.id}`);
    } catch (err) {
      console.log(err);
      navigate(`/my-courses/${params.course_slug}`);
    }
  };

  return (
    <>
      {/* MAIN */}
      <main className="exam user-select-none">
        <section className="exam-title-section">
          <div className="exam-titles-container">
            <div className="title-list">
              <div className="item mb-3">
                <p className="title">
                  <span className="svg-container">{svgList.notePencil}</span>{" "}
                  Exam Title : {examData.exam?.name || "-"}
                </p>
              </div>
              <div className="item mb-3">
                <p className="title">
                  <span className="svg-container">{svgList.marksPaper}</span>{" "}
                  Total Marks : {examData.total_marks || 0}
                </p>
              </div>
              {examData.exam?.duration && (
                <div className="item">
                  <p className="title">
                    <span className="svg-container">{svgList.examClock}</span>{" "}
                    Duration :{" "}
                    {`${Math.round(
                      examData.exam?.duration?.split(":")[0]
                    )} hours ${Math.round(
                      examData.exam?.duration?.split(":")[1]
                    )} minutes `}
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>
        <Form
          onSubmit={(val) => {
            handleSolutionSubmit(val);
          }}
          defaultValues={{}}
        >
          {(register, errors, undefined, subscribedWatchFields) => {
            return (
              <>
                {/* EXAM FINISH OR SUBMIT MODAL */}
                <div
                  className={`overlay ${showSubmitModal ? "show" : "hidden"}`}
                >
                  <section className="exam_submit_modal ">
                    {!timeUp ?
                      <div className="close-btn-container">
                        <button
                          type="button"
                          onClick={() => setShowSubmitModal(false)}
                          class="btn-close"
                        ></button>
                      </div> : null}

                    <div className="text-center mb-3">
                      {!timeUp ? <h3>Are you sure ?</h3> : <h3>Time is up!</h3>}
                    </div>
                    <button
                      type="submit"
                      className="btn_primary btn_md modal_submit_btn"
                    >
                      <span className="paper-plane-icon">
                        {" "}
                        {svgList.paperPlaneTwo}
                      </span>{" "}
                      Submit and Review Answers
                    </button>
                  </section>
                  {/* <div className="overlay hidden" /> */}
                </div>
                <section className="exam-question-section pb-5">
                  <div className="question-container">
                    {examData?.questions?.map((question, index) => {
                      const shouldAppendHeader =
                        question.section__name !== prevSection;
                      prevSection = question.section__name;
                      return (
                        <div key={question.id} id={`${index}`} className="pt-3">
                          {shouldAppendHeader && (
                            <div className="mb-4 section-header-container">
                              <h1 className="mb-4 header">{prevSection}</h1>
                              <hr />
                            </div>
                          )}
                          <div className="question-item">
                            <ExamQuestion
                              errors={errors}
                              question={question}
                              register={register}
                              onChange={onAnswerTap}
                              disabled={selectedQuestions.includes(question.id)}
                              exam_id={params.exam_id}
                              user_exam_id={examData.user_exam.id}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
                <section
                  className={`question-search  px-3 py-4 ${collapsed ? "collapsed" : ""
                    }`}
                >
                  <div className="top-section">
                    <div className="content-container d-flex justify-content-between align-items-center">
                      <div className="item">
                        <button
                          type="button"
                          onClick={() => setCollapsed(!collapsed)}
                          style={{ background: "none" }}
                          className="quick-search-btn"
                        >
                          <span className="icon-container">
                            {svgList.dottedCube}
                          </span>{" "}
                          <span className="d-none d-md-inline-block">
                            {" "}
                            Quick Search
                          </span>
                        </button>
                      </div>
                      <div className="item">
                        <p className="align-items-center">
                          <span>{svgList.timeOutClock}</span>
                          {formatTime(counter)}
                        </p>
                      </div>
                      <div className="item d-flex align-items-center">
                        <div className="submit-btn-container">
                          <button
                            onClick={() => setShowSubmitModal(true)}
                            type="button"
                          >
                            Submit <span> {svgList.paperPlane}</span>
                          </button>
                        </div>
                        <button
                          className="d-none d-md-inline-block"
                          onClick={() => setCollapsed(!collapsed)}
                          type="button"
                        >
                          {svgList.upArrow}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-section mt-4 pb-5">
                    <div>
                      <div className="options-list d-flex flex-wrap">
                        {examData.questions?.map(({ id }, idx) => (
                          <a
                            className={`question-link ${selectedQuestions.includes(id) ? "filled" : ""
                              }`}
                            href={`#${idx}`}
                            key={id}
                          >
                            {idx + 1}
                          </a>
                        ))}
                        {/* {Array.from({ length: 100 }).map((_, idx) => (
                        <a
                          className={`question-link d-none`}
                          href={`#${idx}`}
                          key={idx}
                        >
                          {idx + 2}
                        </a>
                      ))} */}
                      </div>
                    </div>
                  </div>
                </section>
              </>
            );
          }}
        </Form>
      </main>
    </>
  );
};

export default Exam;
