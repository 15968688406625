import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getCourses = async () => {
  return await adminApi({
    endpoint: endpoints.MY_COURSES,
    method: "get",
  });
};

export const getCourseDetails = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.COURSES}${data}/`,
    method: "get",
  });
};

export const progressCourse = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.PROGRESS}${data.id}/`,
    method: "post",
    data: data,
  });
};

export const createExam = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.EXAM}${data.course_id}/${data.exam_id}/`,
    method: "post",
    data: data,
  });
};

export const submitSingleQuestion = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.SINGLE_CHOICE_SUBMIT}${data.user_exam_id}/${data.question_id}/`,
    method: "post",
    data: data,
  });
};

export const createReview = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.REVIEWS}${data.course}/`,
    method: "post",
    data: data,
  });
};

export const questionFinalSubmit = async (data, user_exam_id) => {
  const multiple = data.filter((item) => item.answer instanceof Array);
  let written = data.filter((item) => !(item.answer instanceof Array));

  written = written.map(({ user_exam_question, answer }) => ({
    user_exam_question: user_exam_question.split("_")[0],
    answer,
  }));

  const payload = {
    written,
    multiple,
  };
  return await adminApi({
    endpoint: `${endpoints.FINAL_SUBMIT}${user_exam_id}/`,
    method: "post",
    data: payload,
  });
};
