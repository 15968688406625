import { useEffect } from "react";
import { Controller } from "react-hook-form";

const RatingInput = ({ meta, defaultValue, formInstance }) => {
  const { key, rules = {}, disabled = false } = meta;
  const { control, watch } = formInstance;
  const watchValue = watch(key);

  useEffect(() => {
    console.log(watchValue);
  }, [watchValue]);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={key}
      rules={rules}
      shouldUnregister
      render={({ field: { onChange, value, ref } }) => (
        <div className="star-rating">
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={
                  index <= value
                    ? "star-button star-selected"
                    : "star-button star-unselected"
                }
                onClick={() => !disabled && onChange(index)}
              >
                <span className="star">&#9733;</span>
              </button>
            );
          })}
        </div>
      )}
    />
  );
};

export default RatingInput;
