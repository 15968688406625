import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import Discussion from "../../../components/discussion/Discussion";
import { getMyQuestions } from "../../../services/api/queries/forum";

const MyDiscussion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getMyQuestions()
      .then((info) => {
        if (!info.success) throw info;
        setQuestions(info.data);
        return info;
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    return res;
  }, []);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="My Courses  Page" />
      <Loader className="h-100" minHeight={false} isLoading={isLoading}>
        <div className=" h-100">
          <div className="row d-flex h-100">
            <div className="col-md-12 h-100">
              <div
                className="discussion-section p-lg-5 h-100"
                style={{ cursor: "default" }}
              >
                <div className="discussion-container">
                  {questions.map((question) => (
                    <Discussion
                      key={question.id}
                      question={question}
                      course_id={question.forum.course}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </React.Fragment>
  );
};
export default MyDiscussion;
