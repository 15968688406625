import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getFileNameFromUrl } from "../../utils/urls";

const FileItem = ({ file, setShowModal, setSelectedFile }) => {
  return (
    <div className="file-item">
      <div
        onClick={() => {
          setSelectedFile(file);
          setShowModal(true);
        }}
        key={file}
      >
        <FontAwesomeIcon icon={faFilePdf} className="me-2" size="lg" />
        {getFileNameFromUrl(file)}
      </div>
    </div>
  );
};

export default FileItem;
