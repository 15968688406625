import inputTypes from "../../../constants/form/inputTypes";

const ratingFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "review",
    labelText: "Review",
    placeHolder: "Enter Review",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RATING,
    key: "rating",
    labelText: "Rating",
    placeHolder: "Enter Rating",
    rules: {
      required: true,
    },
  },
];

export default ratingFields;
