import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CheckBox, Form, Input } from "../formBuilder/FormInput";

function SignIn() {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { signIn } = useAuth();

  const onSubmit = async (values) => {
    try {
      await signIn(values.username, values.password);

      navigate("/");
    } catch (error) {
      const message = error.error || "Something went wrong";
      notyf.open({
        type: "danger",
        message: message,
        duration: 5000,
        ripple: false,
        dismissible: true,
        position: {
          x: "center",
          y: "top",
        },
      });
    }
  };

  return (
    <Form onSubmit={onSubmit} class_name="p-5 justify-content-center">
      {(register, errors) => {
        return (
          <>
            <Input
              name="username"
              register={register}
              errors={errors}
              required={true}
              class_name="col-12 mb-3"
              label="Username"
            />
            <Input
              name="password"
              type="password"
              register={register}
              errors={errors}
              required={true}
              class_name="col-12 mb-0"
              label="Password"
            />
            <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>
            <CheckBox
              label="Keep me logged in"
              required={false}
              class_name="col-12 mt-3"
              name="keep_logged_in"
              register={register}
              errors={errors}
            />
            <div className="text-center mt-3">
              <Button type="submit" variant="primary" size="lg">
                Sign in
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export default SignIn;
