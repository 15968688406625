import React, { useCallback, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const SelectInput = ({
  meta,
  defaultValue,
  formInstance,
  isWatchSubscribed,
  dependencyValues,
}) => {
  const {
    key,
    rules = {},
    lookupQuery,
    isMulti = false,
    multiSelectAll = false,
    show = true,
  } = meta;
  const { setValue, control } = formInstance;
  const [dropdownMeta, setDropdownMeta] = useState(
    meta.options
      ? [
          ...(multiSelectAll
            ? [{ label: "Select All", value: "select_all" }]
            : []),
          ...meta.options,
        ]
      : []
  );
  const [display, setDisplay] = useState(show);

  const performLookupQuery = useCallback(
    async (data) => {
      try {
        const res = await lookupQuery(data);
        setDropdownMeta(
          res?.length
            ? [
                ...(multiSelectAll
                  ? [{ label: "Select All", value: "select_all" }]
                  : []),
                ...res,
              ]
            : []
        );
        if (defaultValue) setValue(key, defaultValue);
      } catch (err) {
        setDropdownMeta([]);
        throw err;
      }
    },
    [defaultValue, key, lookupQuery, multiSelectAll, setValue]
  );

  useEffect(() => {
    if (!lookupQuery || meta.dependencies) return;
    performLookupQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performLookupQuery]);

  const toggleVisibilityOfSelect = useCallback((condition) => {
    if (condition) setDisplay(false);
    else setDisplay(true);
  }, []);

  useEffect(() => {
    if (!meta.dependencies?.length || !Object.keys(dependencyValues).length)
      return;
    meta?.onDependencyValueChange(dependencyValues, meta.key, {
      performLookupQuery,
      toggleVisibilityOfSelect,
    });
  }, [meta, performLookupQuery, dependencyValues, toggleVisibilityOfSelect]);

  const handleSelect = useCallback(
    (value) => {
      setValue(key, value);
    },
    [key, setValue]
  );

  useEffect(() => {
    if (isWatchSubscribed) handleSelect(defaultValue);
  }, [defaultValue, handleSelect, isWatchSubscribed]);

  return (
    display && (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={key}
        rules={rules}
        shouldUnregister
        render={({ field: { onChange, value, ref } }) => (
          <Select
            inputRef={ref}
            classNamePrefix="addl-class"
            options={
              Array.isArray(value) && value?.includes("select_all")
                ? [{ label: "Select All", value: "select_all" }]
                : dropdownMeta
            }
            value={dropdownMeta.filter((option) =>
              Array.isArray(value)
                ? value?.includes(option.value)
                : option.value === value
            )}
            onChange={(val) => {
              !isMulti
                ? onChange(val?.value)
                : val.length
                ? val.find((option) => option.value === "select_all")
                  ? onChange(["select_all"])
                  : onChange(val.map((v) => v.value))
                : onChange([]);
            }}
            isClearable
            isMulti={isMulti}
          />
        )}
      />
    )
  );
};

export default SelectInput;
