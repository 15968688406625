import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFieldArray } from "react-hook-form";
import InputBuilder from "../inputBuilder/inputBuilder";

const ListInput = ({ meta, formInstance }) => {
  const {
    key,
    fields,
    addButtonText = "+ Add New",
    addButtonClassName,
    labelPrefix,
    listItemClassName,
  } = meta;
  const { control, watch } = formInstance;

  const {
    fields: fieldsData,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: key,
  });

  const watchValue = watch(key);

  // function moveElement(arr, old_index, new_index) {
  //   if (new_index >= arr.length) {
  //     var k = new_index - arr.length + 1;
  //     while (k--) {
  //       arr.push(undefined);
  //     }
  //   }
  //   arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  //   return arr; // for testing
  // }

  useEffect(() => {
    console.log(watchValue);
  }, [watchValue]);

  const handleDragEnd = ({ source, destination }) => {
    if (source.index && destination.index)
      move(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
      <Droppable droppableId="dropable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fieldsData.map((data, index) => {
              const fieldNamePrefix = `${key}[${index}]`;
              return (
                <Draggable
                  draggableId={`item-${index}`}
                  index={index}
                  key={`item-${index}`}
                >
                  {(provided) => (
                    <div
                      key={fieldNamePrefix}
                      className="d-flex justify-content-between align-items-end mb-3"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <span {...provided.dragHandleProps}>
                        <FontAwesomeIcon
                          icon={faBars}
                          className="me-3"
                          size="lg"
                        />
                      </span>
                      <span className="w-100">
                        <div
                          className={`list-input-row ${
                            listItemClassName || ""
                          }`}
                        >
                          {!!labelPrefix && (
                            <p className="mt-2 mb-1">{`${labelPrefix} ${
                              index + 1
                            }`}</p>
                          )}
                          {fields.map((field, idx) => (
                            <InputBuilder
                              key={idx}
                              renderLabel={false}
                              meta={{
                                ...field,
                                key: `${fieldNamePrefix}.${field.key}`,
                              }}
                              formInstance={formInstance}
                            />
                          ))}
                        </div>
                      </span>
                      <span
                        onClick={() => {
                          remove(index);
                        }}
                        style={{
                          display: "inline-block",
                          width: 8,
                          padding: 0,
                          margin: 0,
                          marginBottom: 10,
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <span
        type="button"
        onClick={() => append()}
        className={addButtonClassName || "link-primary"}
      >
        {addButtonText}
      </span>
    </DragDropContext>
  );
};

export default ListInput;
