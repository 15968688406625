import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Form } from "react-bootstrap";

const QuestionInput = ({ meta, formInstance, defaultValue, isInvalid }) => {
  const {
    key,
    addButtonText = "+ Add New",
    addButtonClassName,
    labelPrefix,
    listItemClassName,
  } = meta;
  const { setValue, setError, clearErrors, register } = formInstance;

  const [fieldsData, setFieldsData] = useState(defaultValue || []);

  const append = useCallback((data = { name: "", correct: false }) => {
    setFieldsData((prev) => [...prev, data]);
  }, []);

  const remove = useCallback((index) => {
    setFieldsData((prev) => prev.filter((d, idx) => idx !== index));
  }, []);

  function moveElement(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  const checkError = () => {
    return !!fieldsData
      .map((data) => data.correct)
      .reduce((acc, cur) => (acc |= cur), false)
      ? true
      : "Must select at least one correct solution.";
  };

  useEffect(() => {
    setValue(key, fieldsData);
  }, [clearErrors, fieldsData, key, setError, setValue]);

  const handleDragEnd = ({ source, destination }) => {
    const newValues = moveElement(fieldsData, source.index, destination.index);
    setFieldsData(newValues);
    setValue(key, newValues);
  };

  return (
    <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
      <Form.Control
        {...register(key, { required: true, validate: checkError })}
        className="d-none"
      />
      <Droppable droppableId="dropable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fieldsData.map((data, index) => {
              const fieldNamePrefix = `${key}[${index}]`;
              return (
                <Draggable
                  draggableId={`item-${index}`}
                  index={index}
                  key={`item-${index}`}
                >
                  {(provided) => (
                    <div
                      key={fieldNamePrefix}
                      className="d-flex justify-content-between align-items-end"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <span {...provided.dragHandleProps}>
                        <FontAwesomeIcon
                          icon={faBars}
                          className="me-3"
                          size="lg"
                        />
                      </span>
                      <span className="w-100">
                        <div
                          className={`list-input-row ${
                            listItemClassName || ""
                          }`}
                        >
                          {!!labelPrefix && (
                            <p className="mt-2 mb-1">{`${labelPrefix} ${
                              index + 1
                            }`}</p>
                          )}
                          <div className="col-9">
                            <Form.Control
                              type="text"
                              className="col-4"
                              placeholder={"Question Name"}
                              value={data?.name}
                              onChange={(e) => {
                                const name = e.target.value;
                                setFieldsData((prev) =>
                                  prev.map((d, idx) =>
                                    idx === index ? { ...d, name } : { ...d }
                                  )
                                );
                              }}
                            />
                          </div>
                          <Form.Check
                            type="checkbox"
                            label="Correct"
                            name="correct"
                            className="col-3"
                            isInvalid={isInvalid}
                            checked={data?.correct}
                            onChange={(e) => {
                              const correct = e.target.checked;
                              setFieldsData((prev) =>
                                prev.map((d, idx) =>
                                  idx === index ? { ...d, correct } : { ...d }
                                )
                              );
                            }}
                          />
                        </div>
                      </span>
                      <span
                        onClick={() => {
                          remove(index);
                        }}
                        style={{
                          display: "inline-block",
                          width: 8,
                          padding: 0,
                          margin: 0,
                          marginBottom: 10,
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <span
        type="button"
        onClick={() => append()}
        className={addButtonClassName || "link-primary"}
      >
        {addButtonText}
      </span>
    </DragDropContext>
  );
};

export default QuestionInput;
