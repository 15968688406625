/* eslint-disable prettier/prettier */
import React from "react";
import { CheckBox, Radio, Textarea } from "../formBuilder/FormInput";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { submitSingleQuestion } from "../../services/api/queries/courses";

const ExamQuestion = ({
  question,
  register,
  onChange,
  errors,
  disabled,
  exam_id,
  user_exam_id,
}) => {
  const singleChoiceSubmit = async (values) => {
    try {
      const res = await submitSingleQuestion(values);
      onChange(values.question_id);
      if (!res.success) throw res.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="question">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {question.name}
        </ReactMarkdown>
      </div>
      {question?.image ? (
        <div className="image-container mx-auto text-center p-0">
          <img src={question?.image} alt="" />
          {/* <img src="/images/markup-images/index.jpeg" alt="Question image" /> */}
        </div>
      ) : null}
      <div className="options">
        {question.q_type === "written" ? (
          <Textarea
            name={`${question.id}_written`}
            register={register}
            errors={errors}
            rows={4}
            class_name="col-12"
            label={"Type your answer here"}
          />
        ) : question.q_type === "single_choice" ? (
          question?.options?.map((option, index) => (
            <Radio
              class_name="option-item"
              key={option.name}
              index={index}
              onChange={(e) => {
                singleChoiceSubmit({
                  question_id: question.id,
                  answer: { key: e.target.value },
                  exam_id: exam_id,
                  user_exam_id: user_exam_id,
                });
              }}
              disabled={disabled}
              errors={errors}
              register={register}
              name={`${question.id}`}
              value={option.key}
              label={option.name}
              labelClassName="question-option"
              placeholder={option.name}
            />
          ))
        ) : (
          question?.options?.map((option, index) => (
            <CheckBox
              class_name="option-item"
              key={option.name}
              index={index}
              onChange={(e) => {
                onChange(question.id);
              }}
              errors={errors}
              register={register}
              name={`${question.id}`}
              value={option.key}
              label={option.name}
              labelClassName="question-option"
              placeholder={option.name}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ExamQuestion;
