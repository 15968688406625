/* eslint-disable prettier/prettier */
import React from "react";
import { renderQuestionMarker } from "../formBuilder/FormInput";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ResultQuestion = ({ question, handleExplanationModalShow }) => {

  console.log(question)

  return (
    <div>
      <div className="question d-flex">
        <div>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {question?.name}
          </ReactMarkdown>
        </div>{" "}
        <span className="d-inline-block ms-2 text-danger">
          {!question.answer ? "(No answer selected)" : ""}
        </span>
      </div>
      {question?.image ? (
        <div className="image-container mx-auto text-center my-2 py-4 ">
          <img src="/images/markup-images/question-image.png" alt="" />
        </div>
      ) : null}
      <div className="options">
        {question.q_type === "written" ? (
          <div className="col-12">
            <label>Your answer</label>
            <textarea
              name={`${question.id}_written`}
              className="form-control "
              rows="4"
              cols="45"
              value={question.answer}
            // disabled
            ></textarea>
          </div>
        ) : question.q_type === "single_choice" ? (
          question?.options?.map((option, index) => (
            <div
              className={`result-option-item single-choice ${!question.answer || question.answer === option.key
                ? option.correct
                  ? "correct"
                  : question.answer && "incorrect"
                : ""
                }`}
            >
              {/* {console.log(question.answer, option)} */}
              <p className="question-option">
                <span className="option-marker">
                  {renderQuestionMarker(index)}
                </span>
                {option?.name}
                <span className="ms-3 icon-container p-0 d-inline-block">
                  {option?.correct && (
                    <img
                      style={{
                        height: "12px",
                        marginTop: "-5px",
                        padding: "0",
                      }}
                      src="/images/Icons/tick-mark.svg"
                      alt="✓"
                    />
                  )}
                  {!option?.correct && question.answer === option.key && (
                    <img
                      style={{
                        height: "12px",
                        marginTop: "-5px",
                        padding: "0",
                      }}
                      src="/images/Icons/rounded-cross.svg"
                      alt="✓"
                    />
                  )}
                </span>
              </p>
            </div>
          ))
        ) : (
          question?.options?.map((option, index) => (
            <div
              className={`result-option-item multiple-choice ${!question.answer || question.answer?.includes(option.key)
                ? option.correct
                  ? "correct"
                  : question.answer && "incorrect"
                : ""
                }`}
            >
              <p className="question-option">
                <span className="option-marker">
                  {renderQuestionMarker(index)}
                </span>
                {option?.name}
                <span className="ms-3 icon-container p-0 d-inline-block">
                  {option?.correct && (
                    <img
                      style={{
                        height: "12px",
                        marginTop: "-5px",
                        padding: "0",
                      }}
                      src="/images/Icons/tick-mark.svg"
                      alt="✓"
                    />
                  )}
                  {!option?.correct &&
                    question.correct_answer?.includes(option.key) && (
                      <img
                        style={{
                          height: "12px",
                          marginTop: "-5px",
                          padding: "0",
                        }}
                        src="/images/Icons/rounded-cross.svg"
                        alt="✓"
                      />
                    )}
                </span>
              </p>
            </div>
          ))
        )}
      </div>
      <div className="explanation-container">
        <div className="">
          <h3 className="title">Explanation</h3>
          <p className="explanation-text">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {question.question__answer_explanation}
            </ReactMarkdown>
          </p>
          {question?.question__video_explanation ?
            <div className="d-flex justify-content-end">
              <a className="explanation-video-opener-btn" onClick={() => handleExplanationModalShow(question?.question__video_explanation || '')}>VIDEO EXPLANATION</a>
            </div> : null}

        </div>
      </div>
    </div>
  );
};

export default ResultQuestion;

