import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getPayments = async (data) => {
  return await adminApi({
    endpoint: endpoints.PAYMENT,
    method: "get",
    params: data,
  });
};
export const getSinglePayment = async (data) => {
  console.log(data);
  return await adminApi({
    endpoint: `${endpoints.PAYMENT}${data.invoice}/`,
    method: "get",
  });
};
