import endpoints from "constants/endpoints";
import { publicApi, adminApi } from "services/api/client";

export const login = async (data) => {
  return await publicApi({
    endpoint: endpoints.USER_LOGIN,
    method: "post",
    data: data,
  });
};

export const logout = async (data) => {
  return await adminApi({
    endpoint: endpoints.LOGOUT,
    method: "delete",
  });
};

export const updateProfile = async (data) => {
  const { u_type, ...newData } = data;
  console.log(newData);
  const payload = {
    ...newData,
    image: Array.isArray(data.image)
      ? data.image[0] || null
      : data.image || null,
  };
  console.log(payload);
  return await adminApi({
    endpoint: `${endpoints.USER_PROFILE}`,
    method: "patch",
    data: payload,
  });
};

export const refreshAccessToken = async (data) => {
  return await publicApi({
    endpoint: `${endpoints.REFRESH_TOKEN}`,
    method: "post",
    data: data,
  });
};
