import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const joinForum = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.JOIN_FORUM}${data.id}/`,
    method: "post",
    data: data,
  });
};

export const createQuestion = async (data) => {
  const payload = { ...data, image: data.image?.[0] };
  return await adminApi({
    endpoint: `${endpoints.QUESTIONS}${data.course_id}/${data.forum_id}/`,
    method: "post",
    data: payload,
  });
};
export const updateQuestion = async (data) => {
  const payload = {
    title: data.title,
    description: data.description,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.QUESTIONS}${data.id}/`,
    method: "patch",
    data: payload,
  });
};
export const updateAnswer = async (data) => {
  const payload = {
    answer: data.answer,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.ANSWERS}${data.question_id}/${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const createAnswer = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.ANSWERS}${data.course_id}/${data.question_id}/`,
    method: "post",
    data: payload,
  });
};

export const getQuestions = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.QUESTIONS}${data.course_id}/${data.forum_id}/`,
    method: "get",
  });
};

export const getMyQuestions = async () => {
  return await adminApi({
    endpoint: `${endpoints.MY_QUESTIONS}/`,
    method: "get",
  });
};

export const getQuestionDetails = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.QUESTION_DETAILS}${data.course_id}/${data.question_id}/`,
    method: "get",
  });
};
