import moment from "moment";
import LinkOpener from "../../../components/actions/LinkOpener";
import { getPayments } from "../../../services/api/queries/payment";

const myPaymentListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Course Name",
        accessor: "course.name",
      },
      {
        Header: "Enrolment Date",
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "Expiration Date",
        accessor: "expiration_date",
        Cell: ({ value }) => (
          <p
            className={
              moment(value).isBefore(moment.now()) ? "text-danger" : ""
            }
          >
            {moment(value).format("MMM DD, YYYY")}
          </p>
        ),
      },
      {
        Header: "Paid Amount",
        accessor: "paid_amount",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <LinkOpener
              key="view_invoice"
              openerText="View Invoice"
              navigateTo={`/view-invoice/${defaultValues.id}`}
            />
          ),
        ],
      },
    ],
    actions: [],
    queryService: getPayments,
  };
};

export default myPaymentListViewMeta;
