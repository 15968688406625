const endpoints = {
  ANSWERS: "forums/user/questions/answers/",
  COURSES: "courses/user/courses/",
  EXAM: "exams/user/exams/",
  FILE_UPLOAD: "s3-upload/",
  FINAL_SUBMIT: "exams/user/final-submit/",
  JOIN_FORUM: "forums/user/join-forum/",
  LOGOUT: "accounts/user/logout/",
  MY_COURSES: "enrollments/user/my-courses/",
  MY_QUESTIONS: "forums/user/my-questions",
  NOTICES: "course-contents/user/notices/",
  PAYMENT: "enrollments/user/enrollments/",
  PROGRESS: "courses/user/courses/progress/",
  QUESTIONS: "forums/user/questions/",
  QUESTION_DETAILS: "forums/user/question-detail/",
  REFRESH_TOKEN: "accounts/public/refresh-token/",
  REVIEWS: "courses/user/course-reviews/",
  SINGLE_CHOICE_SUBMIT: "exams/user/single-choice-submit/",
  USER_EXAM: "exams/user/user-exams/",
  USER_EXAM_RESULT: "exams/user/result/",
  USER_LOGIN: "accounts/public/login/",
  USER_PROFILE: "accounts/user/profile/",
};
export default endpoints;
