/* eslint-disable prettier/prettier */
/* eslint-disable default-case */
import React from "react";
import { Form as BootstrapForm } from "react-bootstrap";
import { useForm } from "react-hook-form";

export function Form({
  defaultValues,
  children,
  onSubmit,
  watchFields = [],
  pb,
}) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    control,
    getValues,
    resetField,
  } = useForm({ defaultValues: defaultValues });
  const subscribedWatchFields = watch(watchFields);
  return (
    <BootstrapForm
      onSubmit={handleSubmit(onSubmit)}
      className={pb ? pb : "pb-4"}
    >
      {children(
        register,
        errors,
        { control, getValues, resetField },
        subscribedWatchFields
      )}
    </BootstrapForm>
  );
}

export function Input({
  prepend,
  register,
  name,
  label,
  labelClassName,
  value,
  type = "text",
  pattern,
  class_name,
  required = false,
  errors,
  rules,
  ...rest
}) {
  return (
    <BootstrapForm.Group className={`${class_name}`}>
      <BootstrapForm.Label>
        {label === undefined ? name.toUpperCase().split("_").join(" ") : label}
      </BootstrapForm.Label>
      <BootstrapForm.Control
        type={type}
        {...register(name, { required })}
        {...rest}
        className="form-control"
        pattern={pattern}
      />
      <InputError error={errors[name]} className={"mt-1"} />
    </BootstrapForm.Group>
  );
}

export function CheckBox({
  register,
  name,
  label,
  labelClassName,
  value,
  pattern,
  class_name,
  required = false,
  errors,
  rules,
  index,
  type = "checkbox",
  ...rest
}) {
  return (
    <div className={class_name}>
      <input
        type={type}
        {...register(name, { required })}
        {...rest}
        className="form-check-input"
        value={value}
        id={`${name}__${value}`}
      />
      <label className={labelClassName} htmlFor={`${name}__${value}`}>
        {" "}
        <span className="option-marker">{renderQuestionMarker(index)}</span>
        {label?.toUpperCase().split("_").join(" ")}
      </label>
      <br />
      {errors[name] && errors[name].type === "required" && (
        <span role="alert" className="text-danger">
          This is required
        </span>
      )}
    </div>
  );
}

export const Textarea = ({
  register,
  name,
  label,
  type = "text",
  class_name,
  required = false,
  errors,
  rules,
  rows = "8",
  ...rest
}) => {
  return (
    <div className={`${class_name}`}>
      <label>
        {label === undefined
          ? name.toUpperCase().split("_").join(" ")
          : label.toUpperCase()}
      </label>
      <textarea
        name={name}
        {...register(name, { required })}
        {...rest}
        className="form-control"
        rows={rows}
        cols="45"
      ></textarea>

      {errors[name] && errors[name].type === "required" && (
        <span role="alert" className="text-danger">
          This is required
        </span>
      )}
    </div>
  );
};

export function Select({
  register,
  options,
  class_name,
  option_name,
  name,
  label,
  required = false,
  errors,
  rules,
  init,
  ...rest
}) {
  return (
    <>
      <div className={`mb-3 ${class_name}`}>
        <label className="">
          {label === undefined
            ? name.toUpperCase().split("_").join(" ")
            : label}
        </label>
        <select
          {...register(name, { required })}
          {...rest}
          className="form-control"
        >
          <option value="">{init}</option>
          {options.map((item, idx) => (
            <option key={idx} value={item.value}>
              {option_name === "name" && item.name}
            </option>
          ))}
        </select>

        <InputError error={errors[name]} className={"mt-1"} />
      </div>
    </>
  );
}

export function Radio({
  register,
  label,
  name,
  type = "radio",
  class_name,
  required = false,
  errors,
  rules,
  value,
  index,
  labelClassName,
  ...rest
}) {
  return (
    <div className={class_name}>
      <input
        type={type}
        {...register(name, { required })}
        {...rest}
        className="form-check-input"
        value={value}
        id={`${name}__${label}`}
      />
      <label className={labelClassName} htmlFor={`${name}__${label}`}>
        <span className="option-marker">{renderQuestionMarker(index)}</span>
        {label}
        {/* {label?.toUpperCase().split("_").join(" ")} */}
      </label>
      <br />
      {errors[name] && errors[name].type === "required" && (
        <span role="alert" className="text-danger">
          This is required
        </span>
      )}
    </div>
  );
}

export const InputError = ({ error, text, className }) => {
  if (!error) return null;

  return error.type === "required" ? (
    <div className={className}>
      <span role="alert" className="text-danger">
        {text || "This is required"}
      </span>
    </div>
  ) : null;
};

export const renderQuestionMarker = (optionIndex) => {
  let text;
  switch (optionIndex) {
    case 0:
      text = "A";
      break;
    case 1:
      text = "B";
      break;
    case 2:
      text = "C";
      break;
    case 3:
      text = "D";
      break;
    case 4:
      text = "E";
      break;
  }

  return text;
};
