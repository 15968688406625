import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "layouts/Auth";
import DashboardLayout from "layouts/Dashboard";

// Guards
import AuthGuard from "components/guards/AuthGuard";

// Constants
import {
  ANSWERS,
  EXAM,
  EXAM_RESULT,
  FORUM_DISCUSSION,
  JOIN_DISCUSSION,
  MY_DISCUSSION,
  NOTICES,
  PAYMENT_HISTORY,
  PROFILE,
  SIGN_IN,
  SINGLE_COURSE,
  VIEW_INVOICE,
} from "./constants/routes";

// Pages
import Blank from "pages/pages/Blank";
import Page404 from "pages/auth/Page404";
import SignIn from "pages/auth/SignIn";
import MyCourses from "./pages/pages/courses/MyCourses";
import SingleCourse from "./pages/pages/courses/SingleCourse";
import Exam from "./pages/pages/courses/Exam";
import ExamResult from "./pages/pages/courses/ExamResult";
import Profile from "./pages/pages/Profile";
import Notices from "./pages/pages/Notices/Notices";
import Join from "./pages/pages/discussion/Join";
import MyDiscussion from "./pages/pages/discussion/MyDiscussion";
import DiscussionAnswers from "./pages/pages/discussion/DiscussionAnswers";
import ForumDiscussion from "./pages/pages/discussion/ForumDiscussion";
import PaymentHistory from "./pages/pages/my-payments/PaymentHistory";
import Invoice from "./pages/pages/Invoice";
import BlankLayout from "./layouts/BlankLayout";

const routes = [
  {
    children: [
      {
        element: (
          <AuthGuard>
            <DashboardLayout children={<Blank />} />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    children: [],
  },
  {
    path: SIGN_IN,
    element: <AuthLayout children={<SignIn />} />,
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout children={<MyCourses />} />
      </AuthGuard>
    ),
  },
  {
    path: SINGLE_COURSE,
    element: (
      <AuthGuard>
        <DashboardLayout children={<SingleCourse />} />
      </AuthGuard>
    ),
  },
  {
    path: PROFILE,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Profile />} />
      </AuthGuard>
    ),
  },
  {
    path: NOTICES,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Notices />} />
      </AuthGuard>
    ),
  },
  {
    path: EXAM,
    element: (
      <AuthGuard>
        <BlankLayout children={<Exam replace={true} />} />
      </AuthGuard>
    ),
  },
  {
    path: EXAM_RESULT,
    element: (
      <AuthGuard>
        <DashboardLayout children={<ExamResult />} />
      </AuthGuard>
    ),
  },
  {
    path: JOIN_DISCUSSION,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Join />} />
      </AuthGuard>
    ),
  },
  {
    path: PAYMENT_HISTORY,
    element: (
      <AuthGuard>
        <DashboardLayout children={<PaymentHistory />} />
      </AuthGuard>
    ),
  },
  {
    path: VIEW_INVOICE,
    element: (
      <AuthGuard>
        <DashboardLayout children={<Invoice />} />
      </AuthGuard>
    ),
  },
  {
    path: MY_DISCUSSION,
    element: (
      <AuthGuard>
        <DashboardLayout
          class_name={"content-less-padding"}
          children={<MyDiscussion />}
        />
      </AuthGuard>
    ),
  },
  {
    path: ANSWERS,
    element: (
      <AuthGuard>
        <DashboardLayout
          class_name={"content-less-padding"}
          children={<DiscussionAnswers />}
        />
      </AuthGuard>
    ),
  },
  {
    path: FORUM_DISCUSSION,
    element: (
      <AuthGuard>
        <DashboardLayout
          class_name={"content-less-padding"}
          children={<ForumDiscussion />}
        />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
