import React, { useEffect } from "react";

import TextInput from "../inputs/TextInput";
import inputTypes from "constants/form/inputTypes";
import InputError from "../inputs/InputError";
import InputLabel from "../label/inputLabel";
import SelectInput from "../inputs/SelectInput";
import TextAreaInput from "../inputs/TextAreaInput";
import RadioInput from "../inputs/RadioInput";
import CheckboxInput from "../inputs/CheckboxInput";
import DateInput from "../inputs/DateInput";
import FileUpload from "../inputs/FileUpload";
import ListInput from "../inputs/ListInput";
import QuestionInput from "../inputs/QuestionInput";
import CreatableSelectInput from "../inputs/CreatableSelectInput";
import RatingInput from "../inputs/RatingInput";

const InputBuilder = ({
  meta,
  defaultValue: defaultValueProp,
  formInstance,
  isWatchSubscribed,
  dependencyValues,
  renderLabel = true,
}) => {
  const { className = "", key, inputType, prependLabel, append } = meta;
  const defaultValue =
    defaultValueProp !== undefined ? defaultValueProp : meta.defaultValue;
  const {
    formState: { errors },
    setValue,
  } = formInstance;
  /* Set Default Value */
  useEffect(() => {
    if (defaultValue !== undefined) setValue(key, defaultValue);
  }, [key, defaultValue, setValue]);

  return (
    <div className={className}>
      {!!prependLabel && prependLabel()}

      {inputType !== inputTypes.CHECKBOX && renderLabel && (
        <InputLabel meta={meta} />
      )}

      {/* TYPE :: Text || Pass */}
      {(inputType === inputTypes.TEXT ||
        inputType === inputTypes.PASSWORD ||
        inputType === inputTypes.NUMBER) && (
        <TextInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}

      {inputType === inputTypes.SELECT && (
        <SelectInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          dependencyValues={dependencyValues}
          defaultValue={defaultValue}
        />
      )}
      {inputType === inputTypes.CREATABLE_SELECT && (
        <CreatableSelectInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          dependencyValues={dependencyValues}
          defaultValue={defaultValue}
        />
      )}

      {inputType === inputTypes.TEXTAREA && (
        <TextAreaInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.DATE && (
        <DateInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.RADIO && (
        <RadioInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}
      {inputType === inputTypes.CHECKBOX && (
        <CheckboxInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}

      {inputType === inputTypes.LIST_INPUT && (
        <ListInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
        />
      )}

      {inputType === inputTypes.QUESTION_INPUT && (
        <QuestionInput
          meta={meta}
          formInstance={formInstance}
          isInvalid={errors?.[key] ? true : false}
          defaultValue={defaultValue}
        />
      )}

      {(inputType === inputTypes.FILE || inputType === inputTypes.IMAGE) && (
        <FileUpload
          meta={meta}
          formInstance={formInstance}
          defaultValue={defaultValueProp}
        />
      )}
      {inputType === inputTypes.RATING && (
        <RatingInput
          meta={meta}
          formInstance={formInstance}
          defaultValue={defaultValueProp}
        />
      )}

      <InputError error={errors?.[key]} />

      {inputType !== inputTypes.SELECT && (
        <span className="form__append">{append?.()}</span>
      )}
    </div>
  );
};

export default InputBuilder;
