/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getCourseDetails,
  progressCourse,
} from "../../../services/api/queries/courses";
import CourseAccordion from "../../../components/course/CourseAccordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import TopicItemDescription from "../../../components/course/TopicItemDescription";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../../services/storage";
import { eventBus } from "../../../services/eventBus";

const svgList = {
  search: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill="#07969D"
        fillRule="evenodd"
        d="M2.683 2.683a9.16 9.16 0 1012.954 12.954A9.16 9.16 0 002.683 2.683zm1.414 1.414a7.16 7.16 0 1110.126 10.126A7.16 7.16 0 014.097 4.097z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#07969D"
        d="M20.094 18.282l-4.13-4.129c-.942-.943-2.356.471-1.413 1.414l4.13 4.13c.942.943 2.356-.472 1.413-1.415z"
      ></path>
    </svg>
  ),
};

const SingleCourse = () => {
  const [courseData, setCourseData] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [progress, setProgress] = useState(0);
  const [dropdownData, setDropdownData] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [completed, setCompleted] = useState([]);
  const { slug } = useParams();

  const handleSelect = useCallback(
    (item) => {
      if (!item.content_type) return;
      setSelectedItem(item);

      const selectedTopics = getLocalStorageData("selected_topics") || {};
      selectedTopics[slug] = `${item?.content_type}_${item?.id}`;
      setLocalStorageData("selected_topics", selectedTopics);
    },
    [slug]
  );

  const getNextItem = useCallback(() => {
    const searchData = Object.keys(courseData.topics || {})
      .map((key) => [...courseData.topics?.[key].map((item) => ({ ...item }))])
      .flat();

    if (!searchData.length) return {};
    if (!selectedItem.id) return searchData[0] || {};

    const currentItemIndex = searchData.findIndex(
      (data) =>
        data.id === selectedItem.id &&
        data.content_type === selectedItem.content_type
    );

    if (currentItemIndex + 1 < searchData.length) {
      return searchData[currentItemIndex + 1];
    }

    return {};
  }, [courseData.topics, selectedItem.content_type, selectedItem.id]);

  const getPreviousItem = useCallback(() => {
    const searchData = Object.keys(courseData.topics || {})
      .map((key) => [...courseData.topics?.[key].map((item) => ({ ...item }))])
      .flat();

    if (!searchData.length || !selectedItem.id) return {};

    const currentItemIndex = searchData.findIndex(
      (data) =>
        data.id === selectedItem.id &&
        data.content_type === selectedItem.content_type
    );

    if (currentItemIndex > 0) {
      return searchData[currentItemIndex - 1];
    }

    return {};
  }, [courseData.topics, selectedItem.content_type, selectedItem.id]);

  const getCourseDetailsData = useCallback(async () => {
    try {
      const data = await getCourseDetails(slug);
      if (!data.success) throw data.data;
      setCourseData(data.data);
      setCompleted(data.data.completed_content[0] || []);
      setDropdownData(
        Object.keys(data.data.topics || {})
          .map((key) => [
            ...data.data.topics?.[key].map((item) => ({ ...item })),
          ])
          .flat()
      );
    } catch (err) {
      console.log(err);
    }
  }, [slug]);

  useEffect(() => {
    eventBus.subscribe("refresh_single_course", () => getCourseDetailsData());

    return () => {
      eventBus.unsubscribe("refresh_single_course");
    };
  }, [getCourseDetailsData]);

  const handleSearchTextChange = useCallback(
    (e) => {
      setSearchText(e.target.value);
      const results = dropdownData.filter((data) =>
        data.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSearchResults(results);
      results.length ? setShowSearchResults(true) : setShowSearchResults(false);
    },
    [dropdownData]
  );

  const handleCompleteAndContinue = useCallback(async () => {
    try {
      if (
        selectedItem.content_type === "lecture" ||
        (selectedItem.content_type === "note" && selectedItem.id)
      ) {
        const data = await progressCourse({
          content_type: selectedItem.content_type,
          content_id: selectedItem.id,
          id: courseData.id,
        });
        if (!data.success) throw data.data;
        if (
          !completed.includes(`${selectedItem.content_type}_${selectedItem.id}`)
        ) {
          setCompleted((prev) => [
            ...prev,
            `${selectedItem.content_type}_${selectedItem.id}`,
          ]);
        }
        setProgress(data.data.progress_percentage);
      }
      if (getNextItem().id) handleSelect(getNextItem());
    } catch (err) {
      console.log(err);
    }
  }, [
    completed,
    courseData.id,
    getNextItem,
    handleSelect,
    selectedItem.content_type,
    selectedItem.id,
  ]);

  const getItemByTypeAndId = () => {
    const searchData = Object.keys(courseData.topics || {})
      .map((key) => [...courseData.topics?.[key].map((item) => ({ ...item }))])
      .flat();

    const selectedTopics = getLocalStorageData("selected_topics") || {};

    if (!searchData.length) return false;
    const itemToSelect = searchData.find(
      (item) => `${item.content_type}_${item.id}` === selectedTopics[slug]
    );
    if (!itemToSelect) return false;
    handleSelect(itemToSelect);
    return true;
  };

  useEffect(() => {
    if (!getItemByTypeAndId()) {
      handleSelect(getNextItem());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData]);

  useEffect(() => {
    if (slug) {
      getCourseDetailsData();
    }
  }, [getCourseDetailsData, slug]);

  useEffect(() => {
    setProgress(courseData.progress_percentage);
  }, [courseData]);

  return (
    <main className="course-details">
      <section className="bottom-section">
        <div className="row flex-column flex-lg-row">
          <div className=" col-lg-7 col-md-12 pe-lg-4 course-list-section">
            <div className="play-section mb-5">
              <TopicItemDescription
                data={selectedItem}
                courseName={courseData.name_bn || courseData.name}
                courseId={courseData.id}
                courseSlug={courseData.slug}
                review={courseData.user_review}
              />
            </div>
          </div>
          <div className=" col-lg-5 col-md-12 pe-lg-4 course-list-section">
            <div className="curriculum-header header next-prev-button-container">
              <button
                onClick={() => {
                  handleSelect(getPreviousItem());
                }}
                className="btn_md btn_primary"
                disabled={!getPreviousItem().id}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  size="lg"
                  className="me-1"
                />{" "}
                Previous Lecture
              </button>
              <button
                onClick={handleCompleteAndContinue}
                className="btn_md btn_primary"
                disabled={
                  !getNextItem().id &&
                  selectedItem?.content_type === "exam" &&
                  completed?.includes(
                    `${selectedItem.content_type}_${selectedItem.id}`
                  )
                }
              >
                {(selectedItem.content_type === "lecture" ||
                  (selectedItem.content_type === "note" && selectedItem.id)) &&
                !completed.includes(
                  `${selectedItem.content_type}_${selectedItem.id}`
                )
                  ? "Complete Lecture"
                  : "Next Lecture"}{" "}
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size="lg"
                  className="ms-1"
                />
              </button>
            </div>
            <div className="course-accordion-container p-3">
              <div className="curriculum-header  header d-flex align-items-center justify-content-between flex-column flex-xxl-row">
                <h3 className="title">Course Curriculum</h3>
                <ul className="d-flex list-unstyled align-items-center mt-2">
                  <li className="me-2">
                    <span>
                      {Object?.keys(courseData?.topics || {})?.length || 0}{" "}
                      sections • {courseData?.total_lecture} lectures
                    </span>{" "}
                  </li>
                  <li style={{ width: 50 }}>
                    <CircularProgressbar
                      value={Math.round(progress || 0.0)}
                      text={`${Math.round(progress || 0.0)}%`}
                    />
                  </li>
                </ul>
              </div>
              <div className="search-bar-container mx-1 my-4 ">
                <div className="d-flex justify-content-between align-items-center search">
                  <input
                    type={"text"}
                    placeholder="Search Lecture"
                    className="w-100"
                    value={searchText}
                    onChange={handleSearchTextChange}
                  />
                  <a
                    className="svg-container cursor-pointer"
                    href={`#${searchText}`}
                  >
                    {svgList.search}
                  </a>
                </div>
                {searchText && showSearchResults ? (
                  <div className="search-result-block justify-content-between">
                    <div className="items">
                      {searchResults.map((i) => (
                        <div
                          key={i.id + "" + i.content_type}
                          className="cursor-pointer"
                          onClick={(e) => {
                            setShowSearchResults(false);
                            setSearchText("");
                            handleSelect(i);
                          }}
                        >
                          <div className="item">
                            <div>
                              <p className="title m-0">{i.name}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="accordion-container mt-1">
                <div className="course-accordion">
                  <CourseAccordion
                    data={Object.keys(courseData.topics || {}).map((key) => {
                      return {
                        section: key,
                        data: courseData?.topics?.[key],
                      };
                    })}
                    selectedItem={selectedItem}
                    setSelectedItem={handleSelect}
                    getNextItem={getNextItem}
                    getPreviousItem={getPreviousItem}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SingleCourse;
