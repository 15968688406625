import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Button, Container, Row, Col, Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import paid from "../../assets/img/paid.png";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { getSinglePayment } from "../../services/api/queries/payment";

const Invoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const params = useParams();
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getSinglePayment(params)
      .then((info) => {
        const { data } = info;
        setData(data);
        setIsLoading(false);
        return info;
      })
      .catch((err) => console.log(err));
    return res;
  }, [params]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="Invoice" />

      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Invoice</h1>
        <Loader isLoading={isLoading}>
          <Card className="w-100">
            <ReactToPrint
              pageStyle={{ margin: 5 }}
              content={reactToPrintContent}
              documentTitle={data?.invoice_no}
              trigger={() => (
                <div style={{ textAlign: "right", margin: 16 }}>
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faPrint} className="me-2" />
                    Print
                  </Button>
                </div>
              )}
            />
            <Card.Body className="m-sm-3 m-md-5 demo-wrap" ref={componentRef}>
              {data.payment_status === "paid" && (
                <img src={paid} alt="paid" className="demo-bg" />
              )}
              <div className="mb-4">
                Hello <strong>{data.name}</strong>,
              </div>

              <Row>
                <Col md="6">
                  <div className="text-muted">Invoice No.</div>
                  <strong>{data.invoice_no}</strong>
                </Col>
                <Col md="6" className="text-md-right">
                  <div className="text-muted">Payment Date</div>
                  <strong>
                    {moment(data.created_at).format("MMM DD, YYYY hh:mm a")}
                  </strong>
                </Col>
              </Row>

              <hr className="my-4" />

              <Row className="mb-4">
                <Col md="6">
                  <div className="text-muted">Client</div>
                  <strong>{data.name}</strong>
                  <p>
                    {data.address} <br /> {data.contact_number} <br />
                  </p>
                </Col>
                <Col md="6" className="text-md-right">
                  <div className="text-muted">Payment To</div>
                  <p>
                    {data.payment_to}({data.payment_status})
                  </p>
                </Col>
              </Row>

              <Table size="sm">
                <thead>
                  <tr>
                    <th>Course Name</th>
                    <th>Subscription Expiry</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.course?.name}</td>
                    <td>{data.expiration_date}</td>
                    <td className="text-end">{data.actual_price} BDT</td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Coupon Deductible Amount </th>
                    <th className="text-end">
                      {data.coupon_deductible_amount} BDT
                    </th>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Net Amount </th>
                    <th className="text-end">{data.net_amount}</th>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Loader>
      </Container>
    </React.Fragment>
  );
};

export default Invoice;
