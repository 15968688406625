import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { getNotices } from "../../../services/api/queries/notices";
import moment from "moment";
import Loader from "../../../components/Loader";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

const Notices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notices, setNotices] = useState([]);

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getNotices().then((info) => {
      const { data } = info;
      setNotices(data);
      setIsLoading(false);
      return info;
    });
    return res;
  }, []);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="Manual Enrolment Page" />
      <Container fluid className="p-0">
        <Loader isLoading={isLoading}>
          <div className="notice-board container">
            <h2 className="page-title text-center mb-5">Notice Board</h2>
            <div className="notice-card-container">
              <div className="notice-board-list container">
                {notices.map((el) => (
                  <div key={el.id} className="notice-board-card d-flex mb-5">
                    <div className="icon-container">
                      <img src="/images/Icons/notice.svg" alt="Icon" />
                    </div>
                    <div className="items w-100">
                      <div className="text-container">
                        <p className="title">{el.title}</p>
                        <p className="date">
                          {moment(el?.created_at).format(
                            "MMM DD, YYYY hh:mm a"
                          )}
                        </p>
                        <div className="description ellipsis-three-line">
                          <ReactMarkdown
                            className="content-text"
                            rehypePlugins={[rehypeRaw]}
                          >
                            {el.description}
                          </ReactMarkdown>
                          <span></span>
                        </div>
                        {/* <p className="description ellipsis-three-line ">{el.description}</p> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Loader>
      </Container>
    </React.Fragment>
  );
};

export default Notices;
