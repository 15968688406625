import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, ProgressBar, Row } from "react-bootstrap";
import { getCourses } from "../../../services/api/queries/courses";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";

const MyCourses = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getCourses().then((info) => {
      const { data } = info;
      setCourses(data);
      setIsLoading(false);
      return info;
    });
    return res;
  }, []);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="My Courses  Page" />
      <Loader isLoading={isLoading}>
        <Container fluid className="p-0 my-courses">
          <h1 className="h3 mb-3 top-title">My Course List</h1>
          {!courses?.length ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <div className="no-course-card">
                <div className="mx-auto text-center">
                  <h3 className="title">You Have No Enrolled Course</h3>
                  <button
                    onClick={() =>
                      window.location.replace(
                        process.env.REACT_APP_WEBSITE_BASE_URL
                      )
                    }
                    className="btn_primary btn_md"
                  >
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Row>
              {courses.map((course, index) => (
                <div
                  key={`all-courses-card-${index + 1}`}
                  className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3"
                >
                  <div className="card mb-5 pb-3 mb-5 mx-sm-auto text-center">
                    <div className="">
                      <div className="img-container mb-3">
                        <img
                          className="card-img-top w-100"
                          src={course.cover_photo}
                          // src="https://images.unsplash.com/photo-1665329929710-644672e5b705?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                          alt="Card cap"
                        />
                      </div>
                      <div className="card-body mb-2 pb-2 text-center">
                        <h2 className="course-title ellipsis-one-line">
                          {course.name}
                        </h2>
                        <p className="course-description ellipsis-two-line">
                          {course.short_description}
                        </p>
                      </div>
                    </div>
                    <div className="progress-bar-section mb-1">
                      <h3 className="title text-center">Course Progress</h3>
                      <div className="d-flex justify-content-evenly align-items-center mb-3">
                        <div className="d-block w-75">
                          <ProgressBar
                            now={Math.round(course.user_course_progress || 0.0)}
                          />{" "}
                        </div>
                        <span className="percentage-text">
                          {Math.round(course.user_course_progress || 0.0)}%
                        </span>
                      </div>
                    </div>

                    <div className="buttons-container mx-auto d-flex justify-content-evenly mb-3 w-100">
                      <button
                        onClick={() => navigate(`/my-courses/${course.slug}`)}
                        className="btn_sm btn_primary"
                      >
                        Continue Learning
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          )}
        </Container>
      </Loader>
    </React.Fragment>
  );
};
export default MyCourses;
