/* eslint-disable prettier/prettier */
import React, { Fragment, useRef } from "react";
import TopicCollapsible from "./TopicCollapsible";

const svgList = {
  play: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#626262"
        fillOpacity="0.75"
        d="M8 14.5l6-4.5-6-4.5v9zM10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      ></path>
    </svg>
  ),
  exam: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="#92929D"
        d="M3.111 11.2h5.445v1.6H3.11v-1.6zm0-3.2h7.778v1.6H3.11V8zm0-3.2h7.778v1.6H3.11V4.8zm9.333-3.2h-3.25C8.866.672 8.01 0 7 0S5.133.672 4.807 1.6H1.556c-.11 0-.21.008-.312.032a1.55 1.55 0 00-.785.44c-.14.144-.257.32-.335.512A1.579 1.579 0 000 3.2v11.2c0 .216.047.432.124.624a1.7 1.7 0 00.335.512c.21.216.482.376.785.44.102.016.203.024.312.024h10.888C13.3 16 14 15.28 14 14.4V3.2c0-.88-.7-1.6-1.556-1.6zM7 1.4c.319 0 .583.272.583.6 0 .328-.264.6-.583.6a.596.596 0 01-.583-.6c0-.328.264-.6.583-.6zm5.444 13H1.556V3.2h10.888v11.2z"
      ></path>
    </svg>
  ),
  note: (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="20"
    fill="none"
    viewBox="0 0 19 20"
  >
    <path
      fill="#92929D"
      fillOpacity="0.95"
      fillRule="evenodd"
      stroke="#9F9F9F"
      strokeLinecap="round"
      strokeOpacity="0.75"
      strokeWidth="0.25"
      d="M8.906 3.649C7.228 2.436 4.9 1.883 3.162 1.676c-.87-.103-1.579.661-1.579 1.604v9.387c0 .943.71 1.707 1.579 1.81 1.738.207 4.066.76 5.744 1.973V3.649zm1.187 12.8c1.678-1.213 4.007-1.765 5.745-1.972.869-.103 1.578-.867 1.578-1.81V3.28c0-.943-.71-1.707-1.579-1.604-1.737.207-4.066.76-5.744 1.973v12.8zM3.371 5.95c.05-.34.354-.575.678-.522 1.031.168 2.193.45 3.29.896a.636.636 0 01.34.808.585.585 0 01-.768.358c-.997-.406-2.071-.668-3.044-.826-.324-.053-.546-.373-.496-.714zm.678 2.812c-.324-.053-.628.18-.678.522-.05.34.172.66.496.713.49.08 1.008.186 1.53.325a.592.592 0 00.72-.453c.08-.335-.112-.675-.43-.76a17.174 17.174 0 00-1.638-.347z"
      clipRule="evenodd"
    ></path>
  </svg>)
};

const CourseAccordion = ({ data, selectedItem, setSelectedItem }) => {
  const firstTimeFlag = useRef(true);
  return (
    <>
      {/* <Accordion alwaysOpen> */}
      {data.map((section, i) => (
        <Fragment key={section.section}>
          {/* <Accordion.Item eventKey={i} > */}
          <TopicCollapsible
            section={section}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            firstTimeFlag={firstTimeFlag}
            svgList={svgList}
            isVisible={selectedItem.topic__name === section.section}
          />
        </Fragment>
      ))}
      {/* </Accordion> */}
    </>
  );
};

export default CourseAccordion;
