/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Collapse } from "react-bootstrap";

const TopicCollapsible = ({
  section,
  setSelectedItem,
  selectedItem,
  svgList,
  firstTimeFlag,
  isVisible = false,
}) => {
  const [visible, setVisible] = useState(isVisible);

  const scrollToView = useCallback(() => {
    if (firstTimeFlag.current) {
      firstTimeFlag.current = false;
      return;
    }

    if (
      visible &&
      selectedItem.id &&
      selectedItem.topic__name === formatTopicKey(section.section).title
    ) {
      document
        .getElementById(`${selectedItem.id}_${selectedItem.content_type}`)
        .scrollIntoView();
    }
  }, [
    firstTimeFlag,
    section.section,
    selectedItem.content_type,
    selectedItem.id,
    selectedItem.topic__name,
    visible,
  ]);

  const formatTopicKey = (str = "") => {
    const strArr = str.split("_");
    const topicTitle = strArr
      .filter((el) => !!(strArr.indexOf(el) + 1 < strArr.length - 1))
      .join(" ");
    const totalTopics = strArr[strArr.length - 1];
    const duration = `${strArr[strArr.length - 2].slice(1, -1).split(":")[0]
      }h${" "}${strArr[strArr.length - 2].slice(1, -1).split(":")[1]}m`;

    return {
      title: topicTitle,
      lectures: `${totalTopics} lectures`,
      duration: duration,
    };
  };

  const scrollToTop = useCallback(() => {
    if (typeof window !== undefined) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(selectedItem).length === 0) return;
    if (!visible) {
      setVisible(
        selectedItem.topic__name === formatTopicKey(section.section).title
      );
    }

    // if (!firstTimeFlag.current) {
    //   scrollToView();
    // } else {
    //   firstTimeFlag.current = false;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  // useEffect(() => {
  //   if (!visible) return;
  //   if (!firstTimeFlag.current) {
  //     scrollToView();
  //   } else {
  //     firstTimeFlag.current = false;
  //   }
  // }, [scrollToView, visible]);

  return (
    <div className={`accordion-item ${visible ? "open" : ""} `} id="here">
      <div
        className={`header d-flex align-items-center justify-content-between ${!visible ? "collapsed" : ""
          } `}
        onClick={() => setVisible((visible) => !visible)}
      >
        <h3 className="title">{formatTopicKey(section?.section)?.title}</h3>
        <span className="attributes d-none d-xxl-inline-block">
          {formatTopicKey(section?.section)?.lectures}
          {" • "}
          {formatTopicKey(section?.section)?.duration}
        </span>
      </div>
      <div className="accordion-body-contents">
        <Collapse in={visible} onEntered={scrollToView}>
          <ul className={"content-list"}>
            {section.data?.map((item, itemIndex) => (
              <li
                onClick={() => {
                  setSelectedItem({ ...item, section: section.section });
                  scrollToTop();
                }}
                key={`course-topic-id ${itemIndex} ${item?.content_type} `}
                id={`${item.id}_${item?.content_type}`}
                className={`list-item ${item.id === selectedItem.id &&
                  item.content_type === selectedItem.content_type
                  ? "selected"
                  : ""
                  } `}
              >
                <h3 className="subject-title mb-1 d-flex">
                  {item?.content_type === "lecture" ? (
                    <span className="icon">{svgList.play}</span>
                  ) : item?.content_type === "exam" ? (
                    <span className="icon">{svgList.exam}</span>
                  ) : (
                    <span className="icon">{svgList.note}</span>
                  )}
                  {item?.name}
                </h3>
              </li>
            ))}
          </ul>
        </Collapse>
      </div>
    </div>
  );
};

export default TopicCollapsible;
