import React from "react";
import { Form } from "react-bootstrap";

function TextInput({ meta, formInstance, isInvalid }) {
  const { key, inputType, placeHolder, rules = {} } = meta;
  const { register } = formInstance;

  return (
    <Form.Control
      type={inputType}
      placeholder={placeHolder || ""}
      isInvalid={isInvalid}
      {...register(key, { ...rules })}
    />
  );
}

export default TextInput;
