import inputTypes from "constants/form/inputTypes";

const questionFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title",
    labelText: "Title",
    placeHolder: "Enter Your Question Title",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "description",
    labelText: "Description",
    placeHolder: "Enter Your Questions Description",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "image",
    labelText: "Question Image:",
    multiple: false,
  },
];

export default questionFields;
