import moment from "moment";
import { getUserExams } from "services/api/queries/exam";
import LinkOpener from "components/actions/LinkOpener";

const examListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Exam name",
        accessor: "exam.name",
      },
      {
        Header: "status",
        accessor: "status",
      },
      {
        Header: "Exam Start",
        accessor: "created_at",
        Cell: ({ cell }) =>
          moment(cell.row.original.created_at).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "Exam End",
        accessor: "end_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <LinkOpener
              key="exam_result"
              openerText="Exam Result"
              navigateTo={`/exam-result/${defaultValues.id}`}
            />
          ),
        ],
      },
    ],
    actions: [],
    queryService: getUserExams,
  };
};

export default examListViewMeta;
