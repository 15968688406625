import React, { useState, useEffect, useContext, useCallback } from "react";
import NotyfContext from "../../../contexts/NotyfContext";
import { uploadFile } from "../../../services/api/upload";
import FilePreview from "./FilePreview";

const FileUpload = ({ meta, formInstance, defaultValue = [] }) => {
  const { key, inputType, placeholder, rules = {}, multiple } = meta;
  const { setValue, watch, register } = formInstance;
  const [files, setFiles] = useState(
    Array.isArray(defaultValue) ? defaultValue : [defaultValue]
  );

  const notyf = useContext(NotyfContext);

  const [watchValue] = watch([key]);

  const uploadFiles = useCallback(
    async (e) => {
      const submittedFiles = Array.from(e.currentTarget.files);
      try {
        const res = await uploadFile(submittedFiles, "student_image");
        if (!res.success) throw res;
        const newFiles = [...files, res?.data.url];
        setValue(key, newFiles);
      } catch (error) {
        const message = error.error || "Something went wrong";
        notyf.open({
          type: "danger",
          message: message,
          duration: 5000,
          ripple: false,
          dismissible: true,
          position: {
            x: "center",
            y: "top",
          },
        });
      }
    },
    [files, key, notyf, setValue]
  );

  useEffect(() => {
    if (watchValue)
      setFiles(Array.isArray(watchValue) ? watchValue : [watchValue]);
  }, [watchValue]);

  const deleteFile = useCallback(
    (file) => {
      const newFiles = files.filter((f) => f !== file);
      setValue(key, newFiles);
    },
    [files, key, setValue]
  );

  return (
    <>
      <div className="form__input__file">
        <input {...register(key, { ...rules })} className="d-none" />
        {!files.length && (
          <input
            type={inputType}
            placeholder={placeholder || " "}
            className="custom-file-label"
            onChange={(data) => {
              uploadFiles(data);
            }}
            multiple={multiple}
          />
        )}
      </div>
      {!!files.length && (
        <div
          id="gallery"
          className="form__input__file form__input__file--preview"
        >
          {files?.map((file) => (
            <FilePreview key={file} file={file} deleteFile={deleteFile} />
          ))}

          {/* {multiple && (
            <label className="custom-file-upload">
              <input
                type={inputType}
                multiple={multiple}
                {...register(`${HELPER_INPUT_KEY_PATTERN}${key}-new`, {
                  ...rules,
                  required: !!files.length,
                })}
              />
              <i className="fa fa-upload" />
              <span>Upload New</span>
            </label>
          )} */}
        </div>
      )}
    </>
  );
};

export default FileUpload;
