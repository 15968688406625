import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ListView from "../../../components/listView/ListView";
import myPaymentListViewMeta from "../../../configs/meta/listViewMeta/myPaymentListViewMeta";

const PaymentHistory = () => {
  return (
    <React.Fragment>
      <Helmet title="My Payments History" />
      <Container fluid className="p-0">
        <ListView meta={myPaymentListViewMeta()} title="Payment History" />
      </Container>
    </React.Fragment>
  );
};

export default PaymentHistory;
