import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlSearchParams } from "../../utils/urls";
import ModalForm from "./ModalForm";

function ModalFormOpener({
  submitService,
  fields,
  openerText,
  heading = "",
  submitButtonText = "Submit",
  defaultValues,
  doNotReset,
  successMessage,
  errorMessage,
  appendPayload,
  openerClassName = "",
  refreshEvent,
  size = "lg",
  style,
  submitButtonDisabled = false,
}) {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  // console.log(defaultValues);

  const onClick = () => {
    setShow(true);
  };
  const submitButton = {
    className: "btn btn-primary rounded-0",
    label: submitButtonText,
    isDisabled: submitButtonDisabled,
  };

  return (
    <>
      <ModalForm
        isVisible={show}
        setIsVisible={setShow}
        onSubmit={submitService}
        title={heading}
        fields={fields}
        submitButton={submitButton}
        submitButtonText={submitButtonText}
        defaultValues={defaultValues}
        refreshEvent={refreshEvent}
        appendPayload={{
          ...appendPayload,
          ...getUrlSearchParams(searchParams),
        }}
        successMessage={successMessage}
        errorMessage={errorMessage}
        size={size}
      />
      <p className={`${openerClassName} mb-0`} style={style} onClick={onClick}>
        {openerText}
      </p>
    </>
  );
}

export default ModalFormOpener;
