import React, { useEffect } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { useLocation } from "react-router-dom";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen, hideFromDisplay, setHideFromDisplay } = useSidebar();
  const router = useLocation();
  const pathName = router.pathname;

  useEffect(() => {
    hideFromDisplay && setHideFromDisplay(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  return (
    <nav
      className={`sidebar ${!isOpen ? "collapsed" : ""} 
      ${hideFromDisplay ? "d-none" : ""}`}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            {/* <Logo /> <span className="align-middle me-3">Instant Job Hub</span> */}
            <img
              src="/images/lms-logo.png"
              alt="Logo"
              style={{ width: "32px" }}
            />{" "}
            <span className="align-middle me-3">Instant Job Hub</span>
          </a>

          <SidebarNav items={items} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
