import React from "react";
import { Form } from "react-bootstrap";

function TextAreaInput({ meta, formInstance, isInvalid }) {
  const {
    key,
    inputType,
    placeHolder,
    rules = {},
    rows = 4,
    disabled = false,
  } = meta;
  const { register } = formInstance;

  return (
    <Form.Control
      as="textarea"
      rows={rows}
      type={inputType}
      placeholder={placeHolder || ""}
      isInvalid={isInvalid}
      disabled={disabled}
      {...register(key, { ...rules })}
    />
  );
}

export default TextAreaInput;
